<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
        <div class="left">
          <!-- <div class="tit">
            <h1>秉匠科技赴超图软件拜访交流</h1>
            <div class="time">2020-11-26</div>
            <div class="info">
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_chaotu01.jpg"></span>
              </p>
              <p>11月25日，受北京超图软件股份有限公司研究院邀请，秉匠科技夏海兵总经理带队前往超图南京分公司进行技术交流，超图研究院副院长、三维研发中心总经理冯振华主持会议。</p>
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_chaotu02.jpg"></span>
              </p>
              <p>会上，夏总介绍了“黑洞”三维实时渲染引擎、秉匠SaaS产品体系、项目案例及未来发展规划。随后，冯总对超图软件现有产品功能、S3M空间三维数据标准编制情况以及最近的研发成果做了相关介绍。互动交流环节，冯总详细了解了黑洞引擎的技术特色，对引擎的卓越性能表示充分赞许，同时也表示国产自主研发之路非常艰难，希望秉匠科技持续坚持，并希望后续两家公司能在技术研发和项目实施中展开深入合作。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_chaotu03.jpg"></span>
              </p>
              <p>夏总也表示目前国家鼓励大力发展自主可控的软件的背景下，双方可在各自领域中推进自主核心技术发展的同时，寻求优势互补、协同创新，为推进BIM、CIM等技术的深入应用添砖加瓦。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_chaotu04.jpg"></span>
              </p>
            </div>
          </div> -->
          <div class="tit">
            <h1>{{title}}</h1>
            <div class="time">{{time}}</div>
            <div class="info" v-for="item in infolist" :key="item.id">
              <p class="p_pic" v-if="item.type === 0">
                 <span style="font-size: 14px;"><img :src="item.info"></span>
              </p>
              <p v-else>{{item.info[0]}}</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tit"><h3><font></font>热点内容</h3></div>
          <ul>
            <li v-for="item in hotList" :key="item.id">
              <!-- <p><router-link>{{item}}</router-link></p> -->
              <p @click="gonewsdetail(item.id)">{{item.newsTitle}}</p>
              <span>{{item.newsTime}}</span>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        // {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        // {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        // {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        // {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ],
      title: '',
      time: '',
      infolist: [],
      newsid: this.$route.query.id,
      id: 0
    }
  },
  created () {
    this.getNews(this.newsid)
    this.getnewslist()
  },
  methods: {
    async getNews (newid) {
      const {data} = await this.$axios.get(`news/getNews/${newid}`)
      this.infolist = []
      this.title = data.data.newsTitle
      this.time = data.data.newsTime
      let newsDescribe = data.data.newsDescribe
      let newsPicUrl = data.data.newsPicUrl
      let id = 0;
      for(let i =0; i< newsPicUrl.length; ++i) {
        for(let j =0;j<newsPicUrl[i].length;j++){
          this.infolist.push({id: id++,info: newsPicUrl[i][j],type:0})
        }
        this.infolist.push({id: id++,info: newsDescribe[i],type:1})
      }
    },
    async getnewslist () {
      const { data } = await this.$axios.get('news/getParams')
      // console.log(data.data)
      if (data.code === 200) {
        data.data.splice(4)
        this.hotList = data.data
        // console.log(this.hotList)
      } else {
        return this.$message.error('请求错误')
      }
    },
    async gonewsdetail (idnum) {
      this.newsid = idnum
      this.getNews(this.newsid)
      // console.log(id)
      // console.log(id)
      // this.$router.push('index')
      // this.$router.push({path:'/newsDetails',query: {id}})
      // console.log('hh')
      // const {data} = await this.$axios.get(`news/getNews/${idnum}`)
      // console.log(data.data)
      // this.title = data.data.newsParticularsTitle
      // this.time = data.data.newsTime
      // let newsDescribe = data.data.newsDescribe
      // let newsPicUrl = data.data.newsPicUrl
      //   let id = 0;
      //   for(let i =0; i< newsPicUrl.length; ++i) {
      //     for(let j =0;j<newsPicUrl[i].length;j++){
      //        this.infolist.push({id: id++,info: newsPicUrl[i][j],type:0})
      //     }
      //     this.infolist.push({id: id++,info: newsDescribe[i],type:1})
      //   }
    }
  },
  mounted () {
    document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
          span{
            img{
              max-height: 400px;
            }
          }
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            cursor: pointer;
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        // .router-link-active{
        //   border-bottom: none;
        // }
      }
    }
  }
}
</style>
